<template>
  <div class="card info">
    <div class="icon">
      <!--img :src="image" />
      <img :src="require(image)" />
      <img :src="`${publicPath}${this.image}`" /-->
      <img :src="require(`@/assets/icons/${image}`)" />
    </div>
    <div class="content">
      <h4>{{ title }}</h4>
      <p>
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["image", "title", "subtitle"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.card {
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 0.5px inset;
  background: rgba(68, 66, 178, 0.1);
}

.info {
  display: grid;
  grid-template-columns: 46px auto;
  gap: 10px;
  width: 100%;
  min-width: 220px;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;

  position: relative;
}

img {
  width: 36px;
  height: 36px;
  /* border-radius: 50px;
  display: grid;
  -webkit-box-align: center;
  place-items: center; */
}

/* .icon p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin: 0px;
  text-align: center;

  padding: 0;
} */

.content {
  display: grid;
  gap: 8px;
  align-self: center;
  text-align: left;
}

.content h4 {
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  max-width: 320px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  margin: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 40px;
}

.content p {
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  overflow: hidden;

  color: rgba(0, 0, 0, 0.7);
}
</style>
