<template>
  <div id="contact">
    <h1>Contact Us</h1>

    <div class="contact-display">
      <div class="contact-container">
        <div class="contact-details">
          <h2>Find Us</h2>
          <div class="footer-links">
            <a
              href="https://www.facebook.com/"
              target="_blank"
              id="facebook"
              class="social-media"
            >
              <img alt="facebook icon" src="@/assets/icons/facebook.svg" />
            </a>

            <a
              href="https://api.whatsapp.com/send?phone=+917305605306"
              target="_blank"
              id="whatsapp"
              class="social-media"
            >
              <img alt="whatsapp icon" src="@/assets/icons/whatsapp.svg" />
            </a>

            <a
              href="mailto:hundia.infotec@gmail.com"
              data-type="mail"
              target="_blank"
              id="mail"
              class="social-media"
              ><img alt="mail icon" src="@/assets/icons/mail.svg" />
            </a>
          </div>
          <p>#73/1, 3rd Main, Conservancy Road,</p>
          <p>Between 4th &amp; 5th Cross,</p>
          <p>Chamrajpet,</p>
          <p>Bangalore - 560018</p>
          <p>Karnataka, India</p>
          <p id="phone"><a href="tel:8147476900">8147476900</a></p>
          <p id="phone"><a href="tel:7305605306">7305605306</a></p>
          <p id="email">
            <a href="mailto:hundia.infotec@gmail.com" data-type="mail"
              >hundia.infotec@gmail.com</a
            >
          </p>
        </div>

        <div class="form-container">
          <iframe
            name="hidden_iframe"
            id="hidden_iframe"
            style="display: none"
            @load="redirect"
          ></iframe>

          <form
            action="https://docs.google.com/forms/d/e/1FAIpQLSfg4VSY5vNBk9Vq8dhMqjmBS1YsIE5OqYTSbPraK09fAedwzA/formResponse"
            method="POST"
            target="hidden_iframe"
            @submit="onSubmit"
            class="form-inline"
          >
            <h2 style="text-align: left">Write To Us</h2>
            <input type="hidden" name="page-number" value="1" />

            <div class="form-box">
              <input
                tabindex="1"
                type="text"
                required
                minlength="5"
                maxlength="70"
                name="entry.2005620554"
                placeholder="Your Name"
              />
            </div>

            <div class="form-box">
              <input
                tabindex="2"
                type="email"
                required
                name="entry.1045781291"
                placeholder="Your E-Mail"
              />
            </div>

            <div class="form-box">
              <input
                tabindex="3"
                type="tel"
                pattern="^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$"
                name="entry.1166974658"
                placeholder="Your Mobile Number"
              />
            </div>

            <div class="form-box">
              <textarea
                required
                minlength="10"
                maxlength="300"
                tabindex="4"
                name="entry.839337160"
                id=""
                cols="80"
                rows="10"
                placeholder="Type your message here..."
              ></textarea>
            </div>

            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  </div>

  <h2>Find Us on Google Maps:</h2>
  <Map />
</template>

<script>
import Map from "@/components/Map.vue";
export default {
  data() {
    return {
      submitted: false,
    };
  },
  components: {
    Map,
  },
  methods: {
    onSubmit() {
      this.submitted = true;
    },
    redirect() {
      if (this.submitted) {
        // window.location = "/form-response";
        this.$router.replace({
          name: "Submitted",
        });
      }
    },
  },
};
</script>

<style scoped>
/* .contact {
  max-width: 760px;
} */

.social-media img {
  margin: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  object-fit: cover;

  filter: invert(11%) sepia(35%) saturate(2850%) hue-rotate(191deg)
    brightness(99%) contrast(91%);
}

#facebook:hover > img,
#facebook:active > img {
  filter: invert(37%) sepia(71%) saturate(4108%) hue-rotate(204deg)
    brightness(99%) contrast(92%);
}

#whatsapp:hover > img,
#whatsapp:active > img {
  filter: invert(89%) sepia(78%) saturate(1439%) hue-rotate(64deg)
    brightness(87%) contrast(90%);
}

#mail:hover > img,
#mail:active > img {
  filter: invert(56%) sepia(84%) saturate(6576%) hue-rotate(345deg)
    brightness(99%) contrast(86%);
}

.footer-links {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  gap: 1em;

  margin: 1rem;
  padding: 1rem;
}

.contact-display {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 1rem auto;
}

.contact-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
}

.contact-container > * {
  flex: 1 1 0;
}

.contact-details {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  gap: 0.7em;
}

.form-inline {
  /* margin: 2rem 1rem; */
  max-width: 500px;
}

input,
select,
option {
  border-width: 1px;
}

input:focus {
  border-color: rgb(63, 114, 175);
  outline: 0;
}

select,
option,
input,
label {
  padding: 0.8rem 1rem;
  margin: 0.5rem 0.1rem;
  font-size: 1.2rem;
}
.form-box {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 0.7em;
}
/*.form-box label {
  display: none;
}*/
.form-box input,
.form-box select,
.form-box textarea {
  flex-basis: 100%;

  border-top: none;
  border-right: none;
  border-left: none;
}

/*
.form-box button {
}
*/

input[type="submit"] {
  background-color: rgb(17, 45, 78);
  border: none;
  color: #fff;
  padding: 16px 32px;
  text-decoration: none;
  margin: 20px;
  cursor: pointer;
  height: 60px;

  max-width: 250px;
}

/*******************************
      Form  Media Queries
*******************************/

@media all and (min-width: 600px) {
  .form-box {
    flex-direction: row;
  }
  .form-box label {
    /* flex-basis: 9rem; */
    flex-grow: 0;
    flex-shrink: 0;
  }
  .form-box input,
  .form-box select,
  .form-box textarea {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .form-box button {
    margin: 0 9rem;
  }
}

.error {
  color: red;
  border-color: red;
  border-style: solid;
  border-width: 3px;
}

.errorMessage {
  color: red;
  padding-top: 0;
}

.button {
  margin: 1em;
  justify-content: center;
  align-items: center;
}

.danger {
  background-color: red;
}

@media only screen and (max-width: 600px) {
  .contact-container {
    flex-direction: column;
  }
}
</style>
