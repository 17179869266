import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/Home.vue";
import Policy from "@/views/Policy.vue";
import FAQ from "@/views/FAQ.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";

import Submitted from "@/views/Submitted.vue";

import CarryBagList from "@/views/carryBag/List.vue";
import CarryBag from "@/views/carryBag/Details.vue";

import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/submitted",
    name: "Submitted",
    component: Submitted,
  },
  {
    path: "/carryBag",
    name: "Carry Bag List",
    component: CarryBagList,
  },
  {
    path: "/carryBag/:id",
    name: "Carry Bag",
    props: true,
    component: CarryBag,
  },
  {
    path: "/:catchAll(.*)",
    component: CarryBagList,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  let documentTitle = `${process.env.VUE_APP_TITLE} - ${to.name}`;
  if (to.params.title) {
    documentTitle += `$( to.params.title )`;
  }
  document.title = documentTitle;

  store.state.mobileNav = false;
  next();
});

export default router;
