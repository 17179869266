<template>
  <h1>About</h1>
  <div class="wrapper">
    <h4>Smart, Affordable Products</h4>

    <p>
      Our online store was founded by like-minded individuals, a group of
      professionals dedicated to building innovative, smart and easy online
      shopping solutions. At Hundia, we guarantee that every single purchase you
      make will be a seamless process from start to finish. Take a look at our
      site and get in touch with questions or concerns.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
