<template>
  <a id="map-image" href="https://goo.gl/maps/NUEDU9V3QF1EcYuAA">
    <picture>
      <source media="(min-width:650px)" srcset="@/assets/images/map.png" />
      <!--source media="(min-width:465px)" srcset="@/assets/images/map.png" /-->
      <img
        class="contained-image"
        src="@/assets/images/map-mobile.png"
        alt="Google Map of Hundia Infotec"
      />
    </picture>
  </a>
</template>

<script>
export default {};
</script>

<style scoped>
/* img {
  max-height: 60vh;
} */

.contained-image {
  width: 100vw;
  /* max-height: 350px; */
  display: block;
  cursor: pointer;
  margin: 0 auto;
  object-fit: scale-down;
  background-size: contain;
  background-position: center center;
}
</style>
