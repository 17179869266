<template>
  <div class="product-display">
    <div class="product-container">
      <div class="product-image">
        <img :src="carryBag.image" />
      </div>

      <div class="product-info">
        <h1>{{ carryBag.name }}</h1>

        <h2>Product Info</h2>

        <p><strong>Product Code</strong> : {{ carryBag.productCode }}</p>

        <div v-for="(value, key) in carryBag.details" :key="key">
          <p>
            <strong> {{ key }} : </strong> {{ value }}
          </p>
        </div>

        <label><strong>Size: </strong></label>
        <select v-model="size">
          <option v-for="(key, value) in carryBag.sizes" :key="key" :value="value" :selected="value === size">
            {{ value }}
          </option>
        </select>
        <br />

        <p v-if="inStock"><strong>Unit Price:</strong> {{ unitPrice }}</p>
        <p v-show="inStock"><strong>Shipping:</strong> {{ shipping }}</p>

        <p v-if="inStock"><strong>GST:</strong> 18%</p>

        <p v-if="inStock"><strong>In Stock</strong></p>
        <p v-else><strong>Out of Stock</strong></p>

        <p>
          <strong> Total with shipping and GST included: </strong>
        </p>

        <p>
          <strong>₹ {{ total }} per piece</strong>
        </p>

        <!--div v-show="this.carryBag.sizes[this.size].price == 8">
          <form ref="smallPaymentForm"></form>
        </div>
        <div v-show="this.carryBag.sizes[this.size].price == 10">
          <form ref="mediumPaymentForm"></form>
        </div>
        <div v-show="this.carryBag.sizes[this.size].price == 11">
          <form ref="largePaymentForm"></form>
        </div>
        <div v-show="this.carryBag.sizes[this.size].price == 15">
          <form ref="extraLargePaymentForm"></form>
        </div>
        <div v-show="this.carryBag.sizes[this.size].price == 18">
          <form ref="extraExtraLargePaymentForm"></form>
        </div-->
        <a class="button create-button" href="https://api.whatsapp.com/send?phone=+917305605306" target="_blank"
          id="whatsapp">
          <button type="button">Order Now</button>
        </a>

        <hr />

        <h2>Return &amp; Refund Policy</h2>
        <p>
          Items should be checked on receipt. Any issues or defects should be
          reported within the day of delivery. The defective items should be
          returned and a refund will be issued when the returned items are
          received by us. Return shipping charges will be borne by us. No
          returns and refunds will be entertained once the checking time has
          passed.
        </p>

        <hr />

        <h2>Shipping Info</h2>
        <p>
          The products will be delivered within 7 working days from the date of
          payment
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      size: '6"×9"×3.2"',
      smallButtonId: null,
      mediumButtonId: null,
      largeButtonId: null,
      extraLargeButtonId: null,
      extraExtraLargeButtonId: null,
    };
  },
  created() {
    this.$store.state.carryBags.filter((carryBag) => {
      if (carryBag.id == this.id) {
        this.$store.state.carryBag = carryBag;
        this.smallButtonId = carryBag.sizes['6"×9"×3.2"'].buttonId;
        this.mediumButtonId = carryBag.sizes['8"×10"×4"'].buttonId;
        this.largeButtonId = carryBag.sizes['10"×12"×4"'].buttonId;
        this.extraLargeButtonId = carryBag.sizes['10"×15"×4"'].buttonId;
        this.extraExtraLargeButtonId = carryBag.sizes['12"×17"×4"'].buttonId;
      }
    });
  },
  mounted() {
    let smallPaymentButtonForm = document.createElement("script");
    smallPaymentButtonForm.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    smallPaymentButtonForm.setAttribute(
      "data-payment_button_id",
      this.smallButtonId
    );
    this.$refs.smallPaymentForm.appendChild(smallPaymentButtonForm);

    let mediumPaymentButtonForm = document.createElement("script");
    mediumPaymentButtonForm.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    mediumPaymentButtonForm.setAttribute(
      "data-payment_button_id",
      this.mediumButtonId
    );
    this.$refs.mediumPaymentForm.appendChild(mediumPaymentButtonForm);

    let largePaymentButtonForm = document.createElement("script");
    largePaymentButtonForm.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    largePaymentButtonForm.setAttribute(
      "data-payment_button_id",
      this.largeButtonId
    );
    this.$refs.largePaymentForm.appendChild(largePaymentButtonForm);

    let extraLargePaymentButtonForm = document.createElement("script");
    extraLargePaymentButtonForm.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    extraLargePaymentButtonForm.setAttribute(
      "data-payment_button_id",
      this.extraLargeButtonId
    );
    this.$refs.extraLargePaymentForm.appendChild(extraLargePaymentButtonForm);

    let extraExtraLargePaymentButtonForm = document.createElement("script");
    extraExtraLargePaymentButtonForm.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    extraExtraLargePaymentButtonForm.setAttribute(
      "data-payment_button_id",
      this.extraExtraLargeButtonId
    );
    this.$refs.extraExtraLargePaymentForm.appendChild(
      extraExtraLargePaymentButtonForm
    );
  },
  computed: {
    carryBag() {
      return this.$store.state.carryBag;
    },
    inStock() {
      return this.carryBag.stockQuantity;
    },
    unitPrice() {
      return "₹" + this.carryBag.sizes[this.size].price + " per piece";
    },
    shipping() {
      if (this.carryBag.shipping > 0) {
        return "₹" + this.carryBag.shipping + " per piece";
      }
      return "Free";
    },
    total() {
      let productTotal =
        (this.carryBag.sizes[this.size].price + this.carryBag.shipping) * 1.18;
      return Math.round((productTotal + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>

<style scoped>
p {
  margin: 1em 0;
}

hr {
  margin: 2rem 0;
}

.product-display {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0 auto;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
}

.product-container>* {
  flex: 1 1 0;
}

.product-info {
  text-align: left;
}

img {
  border: 2px solid #d8d8d8;
  width: 90%;
  margin: 0 auto;
  box-shadow: 2px 15px -12px rgba(0, 0, 0, 0.57);

  max-height: 80vh;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .product-container {
    flex-direction: column;
  }
}
</style>
