import { createStore } from "vuex";

export default createStore({
  state: {
    token: null,
    id: {},
    carryBag: {},
    carryBags: [
      {
        id: 1001,
        name: "Diagonal Multicolour Lines Carry Bag",
        productCode: 1001,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1001.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_IiQpefi1xaIthv" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IjLU0vHV9KCeYd" },
          '10"×12"×4"': { price: 11, buttonId: "pl_IjLf9WdEVq1t8g" },
          '10"×15"×4"': { price: 15, buttonId: "pl_IjLmOTrS3oxd2e" },
          '12"×17"×4"': { price: 18, buttonId: "pl_IjLsIrFVFu9Xrd" },
        },
        shipping: 1,
      },
      {
        id: 1002,
        name: "Yellow Pikachu Smiley Carry Bag",
        productCode: 1002,
        category: "Emoji",
        image: require("@/assets/products/carry-bag/1002.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Ik11xXgTMqSzhP" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IneeX94rhPhDsN" },
          '10"×12"×4"': { price: 11, buttonId: "pl_IneffzEXdx5L2N" },
          '10"×15"×4"': { price: 15, buttonId: "pl_IneitY536QyTlX" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inel5mKBvXLn9F" },
        },
        shipping: 1,
      },
      {
        id: 1003,
        name: "Baby Pink Thick Striped Welcome Carry Bag",
        productCode: 1003,
        category: "Event",
        image: require("@/assets/products/carry-bag/1003.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InexlCQUC7bxEc" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inf5HZkaDL9j1i" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inf9v1pkqzNeiO" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InfCB5KGbehbNm" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InfEaQgSZTgLpL" },
        },
        shipping: 1,
      },
      {
        id: 1004,
        name: "Green Thick Striped Thank You Carry Bag",
        productCode: 1004,
        category: "Event",
        image: require("@/assets/products/carry-bag/1004.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InfKhGHuFHxIOU" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InfNMfRLxewAbm" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InfPXwPdTMIam8" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InfRGHDo3lI32W" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InfSaAzmxl35SY" },
        },
        shipping: 1,
      },
      {
        id: 1005,
        name: "Red Flower Blossom Carry Bag",
        productCode: 1005,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1005.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InfWEaHgRDd5wW" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InfZlD4JF4VPZ2" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Infbj7ub1ZT4Nh" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InfdQAcOpNTsDR" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InfeV5dsjxUHrF" },
        },
        shipping: 1,
      },
      {
        id: 1006,
        name: "Multicolour Thick Striped Carry Bag",
        productCode: 1006,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1006.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InfjdYPiXtnvFw" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InfmA4CxeF96Rm" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InfnEyWekUUSzo" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Infp2jDS7dfJsJ" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InfqgB3FUWnBNJ" },
        },
        shipping: 1,
      },
      {
        id: 1007,
        name: "Golden Segregated Design Carry Bag",
        productCode: 1007,
        category: "Design",
        image: require("@/assets/products/carry-bag/1007.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InheYVcuHa8FPU" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InhgeKAG9NK1hB" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InhkLtakm7h5L0" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InhoCYxxGKeFwO" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InhpTZqGSqNI07" },
        },
        shipping: 1,
      },
      {
        id: 1008,
        name: "Green Winking Emoji Carry Bag",
        productCode: 1008,
        category: "Emoji",
        image: require("@/assets/products/carry-bag/1008.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InhraM5ymw99dI" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InhtG4Z8Huh3tg" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inhudsr1GQLBGG" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inhw2GJmhCDXI0" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InhxM0cXfBaFmC" },
        },
        shipping: 1,
      },
      {
        id: 1009,
        name: "Pink Happy Emoji Carry Bag",
        productCode: 1009,
        category: "Emoji",
        image: require("@/assets/products/carry-bag/1009.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Ini0uqfEyfMsCL" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Ini4I2oFd4N238" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Ini64EDA4ygU3c" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Ini7Wesv0xuYYN" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Ini9HA7YAWLnUp" },
        },
        shipping: 1,
      },
      {
        id: 1010,
        name: "Solid Orange Carry Bag",
        productCode: 1010,
        category: "Solid",
        image: require("@/assets/products/carry-bag/1010.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_IniBopVQyiQu6j" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IniJWsjX0qmnBd" },
          '10"×12"×4"': { price: 11, buttonId: "pl_IniLnysMQEzD5h" },
          '10"×15"×4"': { price: 15, buttonId: "pl_IniOrtkC4kagz0" },
          '12"×17"×4"': { price: 18, buttonId: "pl_IniQkLNU5xSgZS" },
        },
        shipping: 1,
      },
      {
        id: 1011,
        name: "Golden Design Carry Bag",
        productCode: 1011,
        category: "Design",
        image: require("@/assets/products/carry-bag/1011.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_IniTEAc4WcMu0b" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IniUqPHMLQTTws" },
          '10"×12"×4"': { price: 11, buttonId: "pl_IniWnngV8svIVS" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InibNEvK52oWj2" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InickmrWTYpknm" },
        },
        shipping: 1,
      },
      {
        id: 1012,
        name: "Birthday Party Carry Bag",
        productCode: 1012,
        category: "Event",
        image: require("@/assets/products/carry-bag/1012.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inih992XzUmrmT" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IniiPXeyBPIF4B" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InijfgxqYWLEPk" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InilBmi3fdjVDj" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InimldMhXNsnZN" },
        },
        shipping: 1,
      },
      {
        id: 1013,
        name: "Solid Baby Pink Carry Bag",
        productCode: 1013,
        category: "Solid",
        image: require("@/assets/products/carry-bag/1013.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inipb3sv94p7PF" },
          '8"×10"×4"': { price: 10, buttonId: "pl_IniwBNusJVENbp" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InixuHCPLMh4zK" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InizBMncj1yomg" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inj1NrPKgJtPIi" },
        },
        shipping: 1,
      },
      {
        id: 1014,
        name: "Red Thick Striped Welcome Carry Bag",
        productCode: 1014,
        category: "Event",
        image: require("@/assets/products/carry-bag/1014.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inj42hYKnuQ2e6" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inj5oEEog2cfbq" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inj6scHc9XaqiN" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inj82Y41opzjRO" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inj9E4Ty2HYOFl" },
        },
        shipping: 1,
      },
      {
        id: 1015,
        name: "Solid Purple Carry Bag",
        productCode: 1015,
        category: "Soild",
        image: require("@/assets/products/carry-bag/1015.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InjB9yRS2k07hY" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InjChIXflrHl38" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InjE1lB4c7JmgO" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InjFPQqW19ABsq" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InjGasVzwMD5j2" },
        },
        shipping: 1,
      },
      {
        id: 1016,
        name: "Pink Red Flower Decoration Carry Bag",
        productCode: 1016,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1016.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InjIen8w3YXY3s" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InjJwVmU3jImrk" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InjLA5PMHw50Kw" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InjMP1caAXLnRS" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InjNiMSDsLI4QJ" },
        },
        shipping: 1,
      },
      {
        id: 1017,
        name: "Golden Thick Striped Thank You Carry Bag",
        productCode: 1017,
        category: "Event",
        image: require("@/assets/products/carry-bag/1017.jpg"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InjXbuKenI8uHN" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InjYxvaF6e4FMt" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InjaMGX8kAnP7a" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Injbc0wj55kIZJ" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InjglgR2IDIhdb" },
        },
        shipping: 1,
      },
      {
        id: 1018,
        name: "Shop Thank You Carry Bag",
        productCode: 1018,
        category: "Shop",
        image: require("@/assets/products/carry-bag/1018.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InjitN9uYQfGOi" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InjkB9aJwzsblO" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InjlBjjKBYxHmv" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Ink0gJj9q1aqWv" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Ink2AV9lS5cvua" },
        },
        shipping: 1,
      },
      {
        id: 1019,
        name: "Yellow Pink Flower Decoration Carry Bag",
        productCode: 1019,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1019.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Ink4Wo7ptYfCyK" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Ink5wxI0T3Xt3k" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Ink7THHy3qu9mJ" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Ink8i4vCvJgy4z" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Ink9shxZcV8xMJ" },
        },
        shipping: 1,
      },
      {
        id: 1020,
        name: "Yellow Welcome Carry Bag",
        productCode: 1020,
        category: "Event",
        image: require("@/assets/products/carry-bag/1020.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InkCMLdHekZRgu" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InkDc9U0LJV4gO" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InkEzTvNaYdqOM" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InkGA1SYTLXhYf" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InkHHFMMTbGGtg" },
        },
        shipping: 1,
      },
      {
        id: 1021,
        name: "Yellow Wedding Carry Bag",
        productCode: 1021,
        category: "Event",
        image: require("@/assets/products/carry-bag/1021.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InkKQEKZKkuTNx" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InkLc48IHeikqR" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InkMlSSZQXqwNV" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InkPANpqvusuEL" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InkQXrYl50c1Yz" },
        },
        shipping: 1,
      },
      {
        id: 1022,
        name: "Yellow Welcome Carry Bag",
        productCode: 1022,
        category: "Event",
        image: require("@/assets/products/carry-bag/1022.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InkTGRAJOC17Eq" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InkUNR2ctiGrmI" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InkVa20e0aHkSD" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InkX50eBR6yYcy" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InkYQddpaXs0DR" },
        },
        shipping: 1,
      },
      {
        id: 1023,
        name: "Red Heart Pink Flower Carry Bag",
        productCode: 1023,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1023.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InkbFPhMtJe2hf" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InkeTLb3NFTWY3" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InkgxKxvBmvai7" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InkiicxrUhE1qI" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InklKeNJTwyQtY" },
        },
        shipping: 1,
      },
      {
        id: 1024,
        name: "Blue Flower Carry Bag",
        productCode: 1024,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1024.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inkp6tkeHMOF3y" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InkziA4f5A9iGF" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inl18ZNVOrb0cd" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inl2GTmGf2Iqkg" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inl3RgalFEBWDP" },
        },
        shipping: 1,
      },
      {
        id: 1025,
        name: "Multicolour Hearts Carry Bag",
        productCode: 1025,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1025.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inl5xtLaK0OSwN" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inl7g1wdYjnWzR" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inl8ppJuVnX3Du" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InmJlVQdRN3uDS" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InmLMTnDpsQu2N" },
        },
        shipping: 1,
      },
      {
        id: 1026,
        name: "Golden Dots Welcome Carry Bag",
        productCode: 1026,
        category: "Event",
        image: require("@/assets/products/carry-bag/1026.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InmNZoawXzheFh" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InmOwGk8c0Vs2T" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InmQkJ9ZTqiQCd" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InmS5Aq4NrupZk" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InmTidUzj8bij8" },
        },
        shipping: 1,
      },
      {
        id: 1027,
        name: "Pink Purple Flower Garden Thank You Carry Bag",
        productCode: 1027,
        category: "Event",
        image: require("@/assets/products/carry-bag/1027.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InmWz22nuhbgBE" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InmYEVHMDauCrh" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inma42Ltfjs4GO" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inmb90I6U04Aei" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InmcGU4tna23XP" },
        },
        shipping: 1,
      },
      {
        id: 1028,
        name: "Yellow Wedding Carry Bag",
        productCode: 1028,
        category: "Event",
        image: require("@/assets/products/carry-bag/1028.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InmejCwKxD8dwj" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inmfyq1jwFMfVv" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InmhQF8G9Iicap" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inmixl0pIihz60" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inmk1XANqyk6aK" },
        },
        shipping: 1,
      },
      {
        id: 1029,
        name: "Dark Blue Roses Carry Bag",
        productCode: 1029,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1029.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InmpX4EBFknW1N" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InmrIjWTtd27VR" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InmsTODgO00AQp" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InmuEPopQVREF9" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InmvOeZutmJioJ" },
        },
        shipping: 1,
      },
      {
        id: 1030,
        name: "Multicolour Blob Welcome Carry Bag",
        productCode: 1030,
        category: "Event",
        image: require("@/assets/products/carry-bag/1030.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InmzGDDcAe4PVz" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inn104UydlLd9M" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inn2OXOTNznGFl" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inn3O1Sx4r91EY" },
          '12"×17"×4"': { price: 18, buttonId: "pl_Inn4Ru7sBS69F3" },
        },
        shipping: 1,
      },
      {
        id: 1031,
        name: "Solid Black Carry Bag",
        productCode: 1031,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1031.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_Inn6hjkJAvKPOs" },
          '8"×10"×4"': { price: 10, buttonId: "pl_Inn7hmRJOT6C3g" },
          '10"×12"×4"': { price: 11, buttonId: "pl_Inn8kknaR2d3Uw" },
          '10"×15"×4"': { price: 15, buttonId: "pl_Inn9pETntHj7Bg" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InnBdTAeQejYFF" },
        },
        shipping: 1,
      },
      {
        id: 1032,
        name: "Multicolour Stars Carry Bag",
        productCode: 1032,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1032.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InnEZtkjWWfqWB" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InnFtcmyQ0hQMw" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InnHDHIPxTGSAJ" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InnIQYpDaySjq7" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InnJbfWE9fumB1" },
        },
        shipping: 1,
      },
      {
        id: 1033,
        name: "Multicolour Rhombus Carry Bag",
        productCode: 1033,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1033.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InnLZ9lq2oqqQy" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InnNSuxUP2MHRF" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InnOXl0TQTpDSa" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InnPjl3F4oz13h" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InnQoMJ5e5G9u4" },
        },
        shipping: 1,
      },
      {
        id: 1034,
        name: "Multicolour Circles Carry Bag",
        productCode: 1034,
        category: "Generic",
        image: require("@/assets/products/carry-bag/1034.png"),
        details: {
          Material: "Cyber XL Eco-Friendly",
          GSM: "200",
          Coating: "Aqua Coating",
          Print: "Multicolour Offset Printing",
          Handle: "18mm thread with nip lock",
          Packaging: "25 piece bundles",
          "Minimum Order Quantity": "4 bundles (100 pcs)",
        },
        stockQuantity: 10,
        sizes: {
          '6"×9"×3.2"': { price: 8, buttonId: "pl_InnSbvVPM1akAq" },
          '8"×10"×4"': { price: 10, buttonId: "pl_InnTkCVKSfV6ay" },
          '10"×12"×4"': { price: 11, buttonId: "pl_InnUw96Qp3EMSO" },
          '10"×15"×4"': { price: 15, buttonId: "pl_InnVvCcZgGbpkr" },
          '12"×17"×4"': { price: 18, buttonId: "pl_InnWtuUKk0Mw0d" },
        },
        shipping: 1,
      },
    ],
  },
});
