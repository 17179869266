<template>
  <router-link
    class="carryBag-link"
    :to="{ name: 'Carry Bag', params: { id: carryBag.id } }"
  >
    <div class="container">
      <div class="card">
        <div class="image-box">
          <img :src="image" />
        </div>
        <div class="content-box">
          <h2>{{ carryBag.name }}</h2>
          <div class="size">
            <h3>Sizes :</h3>
            <span>S</span>
            <span>M</span>
            <span>L</span>
            <span>XL</span>
            <span>XXL</span>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    carryBag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    image() {
      return this.carryBag.image;
    },
  },
};
</script>

<style scoped>
.carryBag-card {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border: 1px solid #39495c;
  border-radius: 6px;
  margin: 1em;

  background-color: #fff;
}
.carryBag-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.carryBag-link {
  color: #2c3e50;
  text-decoration: none;
}

.capiltalize {
  text-transform: capitalize;
}

img {
  margin: 0;
  width: 250px;
  height: 300px;
  display: inline-block;
  object-fit: contain;
}

.container {
  margin: 1em;
}

.container .card {
  position: relative;
  width: 320px;
  height: 450px;
  /* background: rgb(219, 226, 239); */
  background: rgb(255, 255, 255);
  border-radius: 20px;
  overflow: hidden;
}

/* .container .card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63, 114, 175);
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
} */

/* .container .card:hover:before {
  clip-path: circle(300px at 80% -20%);
} */

/* .container .card::after {
  content: "Bag";
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 10em;
  font-weight: 800;
  font-style: italic;
  color: rgba(0, 0, 0, 0.04);
} */

.container .card .image-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .image-box {
  top: 30%;
  transform: translateY(-30%);
}

.container .card .image-box img {
  position: absolute;
  top: 40%; /*  vary for title height above image */
  left: 50%;
  transform: translate(-50%, -50%) /*rotate(-25deg)*/;
  width: 270px;
}

.container .card .content-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 10;
}

.container .card:hover .content-box {
  height: 150px;
}

.container .card .content-box h2 {
  position: relative;
  /* font-family: "avenir"; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1.5rem 0 0.5rem 0;
}

.container .card .content-box .size,
.container .card .content-box .color {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.container .card:hover .content-box .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .content-box .color {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .content-box .size h3,
.container .card .content-box .color h3 {
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.8em;
  margin-right: 10px;
}

.container .card .content-box .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 0.8em;
  display: inline-block;
  color: #eee;
  background: rgb(17, 45, 78);
  margin: 0 5px;
  transition: 0.5s;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .content-box .size span:hover {
  background: rgb(63, 114, 175);
}

.container .card .content-box a {
  display: inline-block;
  padding: 10px 20px;
  /*background: #000;*/
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  opacity: 0;
  transform: translate(50px);
  transition: 0.5s;
}

.container .card:hover .content-box a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
}
</style>
