<template>
  <h1>Our Policy</h1>
  <div class="wrapper">
    <h4>Because We Care</h4>

    <p>
      At Hundia, we want to give our customers the most enjoyable shopping
      experience, one that will keep them coming back to our store time and time
      again. That’s why we believe that our store policies should be fair, clear
      and transparent. Below you’ll find a list of all our policies.
    </p>
    <br />
    <p>
      If you can’t find the information you’re looking for - please don’t
      hesitate to contact us!
    </p>

    <hr />
    <h4>Return &amp; Refund Policy</h4>
    <p>
      Items should be checked on receipt. Any issues or defects should be
      reported within the day of delivery. The defective items should be
      returned and a refund will be issued when the returned items are received
      by us. Return shipping charges will be borne by us. No returns and refunds
      will be entertained once the checking time has passed.
    </p>

    <hr />

    <h4>Shipping Info</h4>
    <p>
      The products will be delivered within 7 working days from the date of
      payment
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
hr {
  margin: 2rem 0;
}
</style>
