<template>
  <h1>Pick your Carry Bag</h1>
  <div class="search-box">
    <div class="search-wrapper">
      <input type="text" v-model="search" placeholder="Search Carry Bags.." />
      <label>Search Carry Bags:</label>
    </div>
  </div>

  <div class="search-box">
    <label>Category</label>
    <div class="search-wrapper">
      <select v-model="this.category">
        <option
          v-for="option in this.categories"
          :key="option"
          :value="option"
          :selected="option === this.category"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>

  <div class="carryBags">
    <CarryBagCard
      v-for="carryBag in carryBags"
      :key="carryBag.id"
      :carryBag="carryBag"
    />
  </div>
</template>

<script>
import CarryBagCard from "@/components/CarryBagCard.vue";

export default {
  data() {
    return {
      search: "",
      category: "All",
      categories: [
        "All",
        "Generic",
        "Event",
        "Shop",
        "Soild",
        "Design",
        "Emoji",
      ],
    };
  },
  components: {
    CarryBagCard,
  },
  computed: {
    carryBags() {
      let bags = this.$store.state.carryBags;

      if (this.category != "All") {
        bags = this.$store.state.carryBags.filter(
          (bag) => bag.category == this.category
        );
      }

      return bags.filter((carryBag) => {
        return carryBag.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style scoped>
.search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.search-wrapper {
  position: relative;
  /*max-width: 720px;*/
  justify-content: center;
}

.search-wrapper label {
  position: absolute;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  top: 8px;
  left: 12px;
  z-index: -1;
  transition: 0.15s all ease-in-out;
}

.search-wrapper input {
  /*padding: 4px 12px;*/
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: 0.15s all ease-in-out;
  background: white;

  border-radius: 10px;
  border-width: 1px;

  padding: 1px 10px;
}

.search-wrapper input:focus {
  outline: none;
  transform: scale(1.05);
}

.search-wrapper input:focus + label {
  font-size: 10px;
  transform: translateY(-24px) translateX(-12px);
}

.search-wrapper input::-webkit-input-placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 100;
}

.carryBags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

input {
  font: normal normal normal 15px/1.875em avenir-lt-w01_35-light1475496,
    avenir-lt-w05_35-light, sans-serif;
  padding-left: 1em;
  padding-right: 2em;

  color: #575757;
  width: 100%;
  height: 2.9em;
}

/*
@media (max-width: 600px) {
  .search-wrapper {
    max-width: 420px;
  }
}*/
</style>
