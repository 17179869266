<template>
  <h1>Frequently Asked Questions</h1>
  <h4>The Answers You Need</h4>
  <div class="wrapper">
    <div class="question">What are the payment options?</div>
    <p>We support cards, wallets, net banking and UPI payments on the site.</p>

    <div class="question">What is the return policy?</div>
    <p>
      Items should be checked on receipt. Any issues or defects should be
      reported within the day of delivery. The defective items should be
      returned and a refund will be issued when the returned items are received
      by us. Return shipping charges will be borne by us.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrapper {
  text-align: left;
}

.question {
  margin-top: 2em;
  font-size: 1.5rem;
}

p {
  margin-top: 1rem;
}
</style>
