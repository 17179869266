<template>
  <div id="app">
    <AppNav />
    <div class="list-wrapper">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import AppNav from "@/components/AppNav";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  components: { AppNav, Footer },
  created() {
    const tokenString = localStorage.getItem("token");
    if (tokenString) {
      const tokenData = JSON.parse(tokenString);
      this.$store.commit("SET_TOKEN", tokenData);
    }

    const idString = localStorage.getItem("id");
    if (idString) {
      const idData = JSON.parse(idString);
      this.$store.commit("SET_ID", idData);
    }

    const myString = localStorage.getItem("my");
    if (myString) {
      const myData = JSON.parse(myString);
      this.$store.commit("SET_MY_DATA", myData);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
@font-face {
  font-family: "avenir";
  src: url(./assets/fonts/avenir.woff2);
  font-weight: lighter;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "brandon";
  src: url(./assets/fonts/brandon.woff2);
  font-display: fallback;
}

@font-face {
  font-family: "times";
  src: url(./assets/fonts/times.woff2);
  font-display: fallback;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  /* font-family: avenir, Helvetica, Arial, sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* font-size: 15px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  /* color: rgb(39, 40, 95); */
  color: #112d4e;
  /* color: rgb(63, 114, 175); */
  min-height: 100vh;
  position: relative;
  background-color: #fff;
}

.list-wrapper {
  position: relative;
  /* padding-top: 12rem; */
}

body {
  margin: 0;
  /*display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;*/
}

button {
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  background-color: transparent;
  border: solid rgba(0, 0, 0, 1) 1px;
  text-align: initial;
  align-items: center;
  border-radius: 100px;
  font-size: 12px;
  transition: border-color 0.4s ease 0s, background-color 0.4s ease 0s;
  transition-property: border-color, background-color;
  transition-duration: 0.4s, 0.4s;
  transition-timing-function: ease, ease;
  transition-delay: 0s, 0s;
  padding: 1em 2em;
}

button:hover,
button:active {
  background-color: #000;
  color: white;
}

.light-button {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  margin: 1em;
  border: unset;
}

.dark-button {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  margin: 1em;
  border: unset;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.cart {
  margin: 25px 100px;
  float: right;
  border: 1px solid #d8d8d8;
  padding: 10px 30px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  box-shadow: 2px 15px -12px rgba(0, 0, 0, 0.57);
}

.color-circle {
  width: 50px;
  height: 50px;
  margin: 0.8em 0.4em;
  border: 2px solid #d8d8d8;
  border-radius: 50%;
}

.disabledButton {
  background-color: #d8d8d8;
  cursor: not-allowed;
}

h1 {
  margin-top: 1rem;
  font-size: 1.5em;
  font-family: "avenir";
  font-weight: 400;
  text-transform: uppercase;
}

h2 {
  font-size: 1.3em;
  font-weight: 200;
}

h3 {
  font-size: 1.8rem;
  color: #777;
}

h4 {
  margin: 1rem;
}

input {
  width: 100%;
  height: 40px;
  margin: 20px 0;
}

label {
  font-size: 20px;
  margin-bottom: 5px;
}

li {
  font-size: 1em;
}

p {
  font-size: 1rem;
}

select {
  height: 40px;
  font-size: 20px;
  background-color: white;
  cursor: pointer;

  /* padding: 0.8rem 1rem; */
  margin: 0.5rem 0.1rem;
  font-size: 1.2rem;

  border-radius: 5px;
  border-width: 1px;
}

textarea {
  width: 95%;
  height: 70px;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
}

.horizontal-flex {
  display: flex;
  flex-direction: row;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.wrapper {
  max-width: 780px;
  margin: 0 auto;
  padding: 1rem;
}

@media only screen and (max-width: 600px) {
  .list-wrapper {
    flex-direction: column;
  }

  h1 {
    font-size: 2em;
  }

  p {
    font-size: 15px;
  }
}
</style>
