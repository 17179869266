<template>
  <!--h1>Economical Paper Carry Bags</h1-->

  <router-link class="button create-button" :to="{ name: 'Carry Bag List' }">
    <button type="button">Browse Bags</button>
  </router-link>

  <div class="main-image">
    <img
      class="image"
      src="@/assets/images/main-image.png"
      alt="Carry bag"
      rel="preload"
    />
  </div>

  <h1>Economical Paper Carry Bags</h1>
  <div class="wrapper">
    <p>
      We provide a selected range of paper carry bags for a variety of purposes
      and occasions, from shopping to events. The bags are available in many
      sizes for your convenience. Our paper bags come with handles and can be
      purchased in bulk at cheap wholesale prices.
    </p>
  </div>

  <div class="list-container">
    <InfoCard
      image="replacement.png"
      title="Hassle-free Replacement"
      subtitle="Easy replacement policy"
    />

    <InfoCard
      image="delivery.png"
      title="Pan India Delivery"
      subtitle="We deliver across India"
    />

    <InfoCard
      image="payment.png"
      title="100% Secure Payments"
      subtitle="Cards, Net-Banking and UPI supported"
    />
  </div>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";
export default {
  components: {
    InfoCard,
  },
};
</script>

<style scoped>
.image {
  max-width: 80vw;
  max-height: 60vh;
}

.list-container {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.7em;

  /* max-width: 1011px; */
  background: rgba(255, 255, 255, 0.6);
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 0.5px inset;
  border-radius: 20px;
  padding: 1em;
  margin: 1em;
}
</style>
