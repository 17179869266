<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <div class="branding" :class="{ disappear: scrolledNav }">
      <img src="@/assets/logo.svg" alt="logo" @click="homePage" />
      <a href="/">
        <div>
          <h1 class="header-text">Hundia Infotec</h1>
          <p>Print Packaging &amp; Beyond</p>
        </div>
      </a>
    </div>
    <hr />
    <nav>
      <ul class="navigation full">
        <li>
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Carry Bag List' }">Carry Bags</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Policy' }">Store Policies</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'About' }">About Us</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }">Contact</router-link>
        </li>
      </ul>

      <div class="icon menu mobile">
        <img
          src="@/assets/icons/menu.svg"
          @click="toggleMobileNav"
          class="icon-active"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
            <img src="@/assets/logo.svg" alt="logo" @click="homePage" />
          </li>
          <li>
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Carry Bag List' }"
              >Carry Bags</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Policy' }">Store Policies</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'About' }">About Us</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }">Contact</router-link>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      scrolledNav: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    mobileNav() {
      return this.$store.state.mobileNav;
    },
  },
  methods: {
    toggleMobileNav() {
      this.$store.state.mobileNav = !this.$store.state.mobileNav;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },
    homePage() {
      this.$router.push({
        name: "Carry Bag List",
      });
    },
  },
};
</script>

<style scoped>
header {
  background-color: #fff;
  z-index: 99;
  width: 100%;
  position: relative;
  transition: 0.5s ease all;
  color: #000;

  font-family: avenir, Helvetica, Arial, sans-serif;
}

h1 {
  text-transform: uppercase;
  font-size: 2.5em;
  font-family: "brandon";
  margin: 0;
  padding: 0s;
}

.header-text {
  color: rgb(17, 45, 78);
  /* Fallback: Set a background color. */
  /* background-color: red; */

  /* Create the gradient. */
  /* background-image: linear-gradient(
    135deg,
    rgba(253, 105, 144, 1) 0%,
    rgba(9, 9, 121, 1) 59%,
    rgba(0, 212, 255, 1) 100%
  ); */
  /* Set the background size and repeat properties. */
  /* background-size: 100%;
  background-repeat: repeat; */

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  /* background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent; */
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  position: relative;
  transition: 0.5s ease all;
  margin: 0;
}

ul,
a {
  font-weight: 500;
  color: #000;
  list-style: none;
  text-decoration: none;
  margin: 0;
}

li {
  text-transform: uppercase;
  padding: 0.5em;
  margin-left: 0.5em;
}

a {
  font-size: 0.9em;
  transition: 0.5s ease all;
  padding-bottom: 0.25em;
  border-bottom: 1px solid transparent;
}

a:hover,
a.router-link-active,
a.router-link-exact-active {
  color: rgb(63, 114, 175);
  border-color: rgb(63, 114, 175);
}

.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin: 0;
}

hr {
  margin: 0 15%;
  background: linear-gradient(
    135deg,
    rgba(253, 105, 144, 1) 0%,
    rgba(9, 9, 121, 1) 59%,
    rgba(0, 212, 255, 1) 100%
  );
  height: 1px;
  border: 0;
}

img {
  width: 5em;
}

.branding {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 1em;
  padding: 1em; */

  text-decoration: none;
}

.branding a,
a:hover {
  text-decoration: none;
}

.branding p {
  font-size: 1em;
  margin: 0;
}

.icon {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0.3em;
  right: 0.3em;
  z-index: 9;
}

.icon img {
  height: 55px;
  width: 55px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  border-style: outset;
  border-width: thin;
  /*border-color: #000;*/
  padding: 0;
  margin: 0.5em;
  cursor: pointer;
  transition: 0.8s ease all;
}

.icon-active {
  transform: rotate(180deg);
}

.menu {
  background-color: transparent;
  /*filter: invert(43%) sepia(83%) saturate(2160%) hue-rotate(211deg)
    brightness(98%) contrast(87%);*/
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 12em;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  top: 0;
  left: 0;
}

.dropdown-nav li {
  margin-left: 0;
}

.dropdown-nav li a {
  color: #000;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 1s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0);
}

.scrolled-nav {
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.scrolled-nav a {
  color: #000;
}

.scrolled-nav nav {
  padding: 0.1rem 0;
}

.scrolled-nav nav .branding img {
  width: 40px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
/* 
.disappear {
  display: none;
  opacity: 0;
  transition: 0.5s ease all;
} */

.mobile {
  display: none;
}

@media (max-width: 600px) {
  .full {
    display: none;
  }

  .mobile {
    display: block;
  }

  .branding {
    flex-direction: column;
    margin: 0.5em;
    padding: 0.5em;
  }

  h1 {
    font-size: 1.8em;
  }
}
</style>
